import styled from "styled-components"

export default styled.div`
  width: calc(100vw - 40px);
  margin-left: auto;
  margin-right: auto;
  max-width: 1600px;
  font-family: 'Poppins', sans-serif;
  font-size: 25px;
  letter-spacing: 0.01em;
  padding-bottom: 40px;
  color: #fff;

  @media (max-width: 900px) {
    font-size: 16px;
  }

  h1 {
    line-height: 130%
  }
`;
